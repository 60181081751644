export default {
  authorizedRedirections: [
    /^\/$/,
    /^\/publish/,
    /^\/login/,
    /^\/explorer/,
    /^\/portal/,
    /^\/maps/,
  ],
  client: {
    name: "Neogeo",
    email: "contact@neogeo.fr"
  },
  forms: {
    signin: {
      title: {
        en: "Enter your login",
        fr: "Saisissez vos identifiants",
      }
    },
    signup: {
      disabled: false,
      hiddenFields: [],
      requiredFields: [
        'first_name',
        'last_name',
        'email',
      ],
    },
    organisation: {
      hiddenFields: [],
      requiredFields: [
        'name',
        'type',
      ],
    },
  }
};
